import React from "react";
import LazyLoad from "react-lazyload";

const MyLazyLoad = ({
  children,
  once = true, // should or not detect the scroll/resize events once the lazy-loaded item is loaded
  offset = 200, // Load the item even if it is 200px below the viewport
  debounce = 300, // wait time for scroll/resize events
  ...restProps
}) => (
  <LazyLoad once={once} offset={offset} debounce={debounce} {...restProps}>
    {children}
  </LazyLoad>
);
export default MyLazyLoad;
