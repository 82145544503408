import React from "react";
import { Image } from "react-native";
import config from "config";
import MyLazyLoad from "screens/common/LazyLoad";

const S3Image = ({
  name,
  path = null,
  style,
  type = "svg",
  resizeMode = "contain",
  imageUrl = null,
  islazyLoadRequired = false,
  ...props
}) => {
  const imageComponent = (
    <Image
      source={{
        uri: imageUrl || (path
          ? `${config.S3_URL}/${path}/${name}.${type}`
          : `${config.S3_URL}/${name}.${type}`)
      }}
      style={style}
      resizeMode={resizeMode}
      {...props}
    />
  );
  if (islazyLoadRequired) {
    return <MyLazyLoad>{imageComponent}</MyLazyLoad>;
  }
  return imageComponent;
};

export default S3Image;
